@import "torvehallerne-fonts";

.torvehallerneginbar,
.torvehallernehotelshop {
  $white: #fff;
  $gray: #aaa;
  $black: #303030;

  $button-background-color: $black;
  $button-text-color: $white;

  $minicart-text-color: $white;
  $minicart-bubble-background-color: $gray;
  $minicart-bubble-text-color: $white;

  $counter-background-color: $gray;
  $counter-text-color: $black;

  $sidebar-background-color: $white;
  $sidebar-text-color: $black;
  $sidebar-border-color: transparent;
  $sidebar-hover-background-color: $black;
  $sidebar-hover-text-color: $white;
  $sidebar-accent-background-color: $black;
  $sidebar-accent-text-color: $white;

  $grid-background-color: $black;
  $grid-text-color: $white;

  $header-height-md: 100px;
  $main-font: "klavika-web", sans-serif;

  font-family: $main-font;

  .button {
    background-color: $button-background-color;
    color: $button-text-color;
    font-family: $main-font;
  }

  .header-container .header {
    @include md {
      height: $header-height-md;
    }

    .minicart {
      .ordernumber {
        background-color: $minicart-bubble-background-color;
        color: $minicart-bubble-text-color;
      }

      .ordertotal {
        color: $minicart-text-color;
      }
    }
  }

  .counter {
    .counter-text {
      color: $counter-text-color;
    }

    .count-btn {
      background-color: $counter-background-color;
    }
  }

  .sidebar {
    @include md {
      background-color: $sidebar-background-color;
    }

    .sidebar-item {
      color: $sidebar-text-color;

      &.active {
        background-color: $sidebar-accent-background-color;
        color: $sidebar-accent-text-color;
      }

      &:hover {
        background-color: $sidebar-hover-background-color;
        color: $sidebar-hover-text-color;
      }
    }
  }

  .category-grid {
    .gridbox-title {
      background-color: $grid-background-color;
      color: $grid-text-color;
    }
  }
}
