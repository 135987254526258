@use "assets/styles/global" as *;

.content-section {
  text-align: left;
  padding: 1rem 0.5rem;
  margin-bottom: 2rem;

  box-shadow: $box-shadow-color 0px 0px 15px;

  @include md {
    padding: 1.5rem 1rem;
    margin: 0 0.5rem 2rem 0.5rem;
  }

  .content-title-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .content-title {
    font-size: 22px;

    @include md {
      font-size: 26px;
    }

    .number {
      font-size: 1.2em;
      margin-right: 3px;
    }
  }

  .content-body {
    margin-top: 1rem;
  }
}
