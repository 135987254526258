@use "assets/styles/global" as *;

.checkout-content {
  .content-sections {
    margin-top: 2rem;
  }

  .orderlist .item-name-number {
    display: flex;
    flex-grow: 1;
    max-width: 50%;
  }

  .checkbox-container {
    display: flex;
    justify-content: flex-end;

    .checkbox-label {
      align-items: center;
      justify-content: flex-end;

      @include md {
        justify-content: flex-end;
      }
    }
  }
}
