@use "assets/styles/global" as *;

.delivery-picker {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;

  @include md {
    justify-content: flex-start;
  }
}

.delivery-picker-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  text-align: center;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;

  @include md {
    width: 18rem;
  }

  &.active {
    background-color: $primary-color;
    font-weight: normal;
  }

  .option-text {
    margin: 0.6rem 0;
    font-size: 13px;
    text-transform: uppercase;
  }

  .delivery-disabled-text {
    opacity: 0.5;
    font-size: 9px;
    font-style: italic;
    font-weight: normal;
    margin-left: 0.5rem;
    text-align: left;

    @include md {
      font-size: 11px;
    }
  }
}
