@use "assets/styles/global" as *;

.product {
  animation-duration: 500ms;
  animation-name: fadein;

  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: $box-shadow-color 0px 0px 15px;

  overflow: hidden;

  &.blocked {
    display: none;
    pointer-events: none;
  }
}

.product-content {
  display: flex;
  flex-wrap: wrap;

  @include md {
    flex-wrap: nowrap;
  }

  .product-image {
    height: 220px;
    width: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include md {
      height: auto;
      width: auto;

      min-height: 130px;
      min-width: 20%;
    }
  }

  .product-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex-grow: 1;

    @include md {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .product-name-container {
    text-align: left;

    .product-name {
      font-weight: 900;
      font-size: 18px;
      color: $text-color;

      @include md {
        font-size: 28px;
      }
    }

    .product-description {
      margin: 0.5rem 0 0 0;
      white-space: pre-line;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .product-action-container {
    @include md {
      width: auto;
      min-width: 13rem;
      padding: 0.5rem 0 0 1rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border-left: 1px dashed $border-color;
    }
  }

  .product-price {
    margin: 0 0 1rem 0;
    text-align: right;
    font-size: 32px;
    font-weight: 600;

    color: $text-color;

    @include md {
      font-size: 40px;
    }

    .product-price-text {
      font-size: 28px;
      margin-left: 0.3rem;

      @include md {
        font-size: 32px;
      }
    }
  }

  .modifier-button {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 2px solid $button-color;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $button-color;
    color: $button-text-color;
    font-size: 15px;

    cursor: pointer;

    @include md {
      font-size: 16px;
      justify-content: center;
      padding: 0.4rem;
    }

    &:focus {
      outline: none;
    }

    .collapsible-icon {
      line-height: 0;
      margin-left: 5px;
    }
  }
}
