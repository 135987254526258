@use "assets/styles/global" as *;

.react-datepicker-wrapper {
  /* Placeholder */
  width: 100%;

  input {
    width: calc(100% - 2.8em);
    padding: 1.25em 1.4em;
    height: 1em;
    font-size: 16px;
    font-weight: bold;

    border: none;
    background-color: $input-color;
    font-family: $main-font;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.react-datepicker-popper {
  /* Calendar */
  z-index: 3;
  width: calc(100% - 4rem);

  @include md {
    width: calc(590px - 1.6rem);
    /* Siden checkout er max 1180px */
  }

  .react-datepicker {
    width: calc(100% - 2px);
    border-radius: 0;
    border: 1px solid $border-color;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__month-container {
      width: 90%;
      margin-left: 5%;

      .react-datepicker__header {
        border-radius: 0;
        background-color: white;
        border-bottom: 1px solid #b7b7b7;

        .react-datepicker__current-month {
          padding: 0.7rem 0;
          text-transform: uppercase;
        }

        .react-datepicker__day-names {
          display: flex;
          justify-content: space-between;
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;

          .react-datepicker__day {
            width: 100%;
            border-radius: 0;
            font-weight: 300;
          }

          .react-datepicker__day--selected {
            background-color: #b7b7b7;
          }

          .react-datepicker__day--keyboard-selected {
            background-color: #b7b7b7;
            color: #303030;
          }
        }
      }
    }
  }
}

.datepicker-section.selected > div > div > input::placeholder {
  color: $input-text-color;
}

// Hiding the calendar options while loading
.hide-calendar .react-datepicker__month-container,
.hide-calendar .react-datepicker__today-button {
  display: none !important;
}

.dates-loading-container {
  .dates-loading-text {
    padding: 1.5rem 1rem;
    margin: 0;
    color: $text-color;
    font-family: $main-font;
    font-size: 16px;
    font-weight: bold;
  }
}
