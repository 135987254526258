@use "assets/styles/global" as *;

.logo-container {
  text-align: center;
  
  @include md {
    text-align: center;
  }
}

.logo {
  cursor: pointer;
  max-height: 3rem;
  align-self: center;
  margin: 0 auto;

  max-width: 7rem;

  @include md {
    max-width: 12rem;
    max-height: 6rem;
    z-index: unset;
    cursor: pointer;
  }
}
