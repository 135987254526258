@use "assets/styles/global" as *;

.maintenance-page {
  .maintenance-icon-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
  }
  
  .maintenance-icon {
    min-height: 3rem;
  }

  .page-title {
    margin: 0;
  }
}
