@use "assets/styles/global" as *;

.input-item select {
  appearance: none;
  background-color: $input-color;
  color: $input-text-color;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  height: 59px;
  padding: 0 20px;
  width: 100%;
  
  &:focus {
    outline: none;
  }
  
  &.empty {
    color: rgba($input-text-color, 0.5);
    font-weight: 600;
  }
}
