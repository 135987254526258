@use "assets/styles/global" as *;

.spinner {
  position: absolute;
  left: calc(50% - 30px);
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-radius: 100%;

  border-left: 6px solid $spinner-background;
  border-right: 6px solid $spinner-background;
  border-bottom: 6px solid $spinner-background;
  border-top: 6px solid $spinner-color;
}

.spinner-section {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;

  .spinner-background {
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.8;
  }

  .fullpage-spinner {
    position: absolute;
    left: calc(50% - 30px);
    top: 50%;
    height: 60px;
    width: 60px;
    margin: 0px auto;
    -webkit-animation: rotation 0.6s infinite linear;
    -moz-animation: rotation 0.6s infinite linear;
    -o-animation: rotation 0.6s infinite linear;
    animation: rotation 0.6s infinite linear;
    border-radius: 100%;

    border-left: 6px solid rgba(48, 60, 55, 0.15);
    border-right: 6px solid rgba(48, 60, 55, 0.15);
    border-bottom: 6px solid rgba(48, 60, 55, 0.15);
    border-top: 6px solid rgba(48, 60, 55, 0.8);
  }

  .information-spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fullpage-spinner {
      position: relative;
      left: unset;
      top: unset;
    }
  }
}
