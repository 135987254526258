@use "assets/styles/global" as *;

.header-container {
  width: 100%;
  max-height: 100vh;
  max-height: -webkit-fill-available;

  box-shadow: $box-shadow-color 0px 0px 10px;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  z-index: 10; // Making sure it's always above the rest of the site

  @include md {
    position: absolute;
  }

  &.expanded-header-container {
    height: 100vh;
    height: -webkit-fill-available;

    display: flex;
    flex-direction: column;

    @include md {
      height: auto;
      overflow: auto;
    }
  }

  .more-actions-content {
    box-sizing: border-box;
    display: none;
    justify-content: flex-end;
    width: 100%;

    @include md {
      display: flex;
    }

    a {
      padding: 0.5rem;
    }
  }
}
