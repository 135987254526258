@use "assets/styles/global" as *;

.timepicker {
  height: auto;
  width: 100%;

  .timepicker-trigger {
    width: calc(100% - 2.8em);
    padding: 1.25em 1.4em;
    font-size: 16px;
    height: 1em;
    font-weight: bold;

    border: none;
    background-color: $input-color;

    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .timepicker-root {
    padding: 0.5rem;

    background-color: white;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    @include md {
      padding: 1rem;
    }

    .picker-container {
      touch-action: none;
    }

    .timepicker-empty {
      color: black;
    }

    .timepicker-header {
      text-align: center;
      padding: 1rem 0;
      color: black;
    }

    .timepicker-body {
      ul.timepicker-row {
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        overflow: hidden;

        li {
          padding: 0.5rem 0;
          font-size: 13px;
          color: black;
          text-align: center;
          opacity: 0.5;
          cursor: pointer;

          @include md {
            min-width: 2rem;
            font-size: 16px;
          }
        }

        li.active {
          opacity: 1;
        }

        li.full {
          color: red;
        }

        li.timepicker-body-header {
          color: #000;
          opacity: 1;
          font-weight: bold;
          min-width: 22px;
          text-align: center;
          border-right: 1px solid #b7b7b7;

          @include md {
            min-width: 2rem;
          }
        }
      }
    }
  }
}
