.oasia {
  $black: #000;
  $accent: #b21917;
  $white: #fff;
  $transparent-white: hsla(0, 0%, 100%, 0.85);
  $light-gray: #ececec;
  $dark-gray: #303030;

  $link-color: $black;

  $button-background-color: $black;
  $button-text-color: $white;
  $button-accent-background-color: $accent;

  $header-background-color: $black;
  $header-text-color: $white;

  $minicart-text-color: $white;
  $minicart-bubble-background-color: $black;
  $minicart-bubble-text-color: $white;

  $sidebar-background-color: $black;
  $sidebar-text-color: $white;
  $sidebar-border-color: $white;
  $sidebar-hover-background-color: $accent;
  $sidebar-accent-background-color: $accent;

  $page-title-color: $white;

  $grid-background-color: $transparent-white;
  $grid-text-color: $dark-gray;

  $counter-background-color: $black;
  $counter-text-color: $dark-gray;

  $product-background-color: $white;
  $precheckout-background-color: $white;
  $checkout-background-color: $white;
  $content-background-color: $white;

  $spinner-background-color: rgba(48, 60, 55, 0.15);
  $spinner-color: rgba(48, 60, 55, 0.8);

  $main-font: "Merriweather", sans-serif;
  $accent-font: "Raleway", sans-serif;

  $header-height-md: 150px;

  font-family: $main-font;

  a {
    color: $link-color;
  }

  .button {
    background-color: $button-background-color;
    color: $button-text-color;
    font-family: $main-font;
  }

  .header-container {
    background-color: $header-background-color;

    .header {
      background-color: $header-background-color;

      @include md {
        height: $header-height-md;
      }

      .mobile-sidebar-button {
        color: $header-text-color;
      }

      .more-button {
        color: $button-text-color;
        font-family: $main-font;
      }

      .minicart-section {
        .more-button,
        .minicart {
          height: 100%;
        }

        .language-picker span {
          font-family: $main-font;
        }
      }

      .minicart {
        .ordernumber {
          background-color: $minicart-bubble-background-color;
          color: $minicart-bubble-text-color;
        }

        .ordertotal {
          color: $minicart-text-color;
          font-family: $accent-font;
        }
      }
    }

    .more-actions-content {
      background-color: $header-background-color;
    }

    .sidebar-container .sidebar {
      background-color: $sidebar-background-color;

      .sidebar-item {
        color: $sidebar-text-color;
        font-family: $main-font;

        &.active {
          background-color: $sidebar-accent-background-color;
        }

        &:hover {
          background-color: $sidebar-hover-background-color;
        }

        .dot {
          background-color: $sidebar-text-color;
        }
      }
    }
  }

  .expandedcart .expandedcart-inner {
    .cart-title {
      font-family: $accent-font;
    }

    .cart-cta-button .button {
      background-color: $button-accent-background-color;
      font-family: $main-font;
    }
  }

  .page-view {
    @include md {
      padding-top: $header-height-md;
    }
  }

  .page-title {
    color: $page-title-color;
    font-family: $accent-font;
    font-size: 30px;
    margin: 1rem;
    margin-top: 3rem;
  }

  .page-subtitle {
    color: $page-title-color;
  }

  .category-grid {
    .gridbox-image {
      height: auto;
      padding: 45%;
    }

    .gridbox-title {
      background-color: $grid-background-color;
      color: $grid-text-color;
      font-family: $accent-font;
    }
  }

  .sidebar {
    @include md {
      background-color: $sidebar-background-color;
    }

    .sidebar-item {
      border-radius: 0;
      color: $sidebar-text-color;

      &.active {
        @include md {
          background-color: $sidebar-accent-background-color;
        }
      }

      &:hover {
        @include md {
          background-color: $sidebar-hover-background-color;
          color: $sidebar-text-color;
        }
      }
    }
  }

  .content-body .product {
    background-color: $product-background-color;
    border-radius: 8px;

    &.modifiers-expanded .product-price {
      display: none;
    }

    .product-image {
      background-position: 50%;
      height: auto;
      min-height: 10rem;
      padding: 30%;

      @include md {
        padding: 0;
        width: 20%;
      }
    }

    .product-name {
      font-size: 1rem;
      font-family: $accent-font;

      @include md {
        font-size: 28px;
      }
    }

    .product-price {
      font-family: $accent-font;
      font-size: 24px;

      @include md {
        font-size: 40px;
      }
    }

    .modifier-button {
      background-color: $button-background-color;
      color: $button-text-color;
      display: none;
    }
  }

  .counter {
    .counter-text {
      color: $counter-text-color;
    }

    .count-btn {
      background-color: $counter-background-color;
    }
  }

  .product-modifier-footer {
    .simple-total {
      border: none;
      font-family: $accent-font;
      font-size: 8px;

      @include md {
        font-size: 20px;
      }
    }
  }

  .precheckout-content {
    background-color: $precheckout-background-color;

    .precheckout-products {
      .product-name,
      .product-price {
        font-family: $main-font;
      }

      .product-price {
        font-size: 1rem;
      }
    }
  }

  .checkout-content {
    .discount-container .button,
    .giftcard-container .button {
      font-family: $main-font;
    }
  }

  .checkout-content .content-sections {
    background-color: $checkout-background-color;
    padding: 0.5rem;

    .room-service-message.oasia {
      display: block;
    }

    .total-title,
    .total-amount {
      font-family: $accent-font;
      font-size: 22px;
    }

    .content-title-container .content-title {
      font-family: $accent-font;
    }

    .delivery-picker-option.active {
      background-color: $button-background-color;
      color: $button-text-color;
    }

    .react-datepicker__input-container input {
      font-family: $main-font;
    }

    .finalize-buttons .button {
      font-family: $main-font;
    }

    .pay-online-button {
      background-color: $button-accent-background-color;
    }
  }

  .error-message-content,
  .confirmation-content,
  .maintenance-page .content-body {
    background-color: $content-background-color;
  }

  .spinner-section {
    .spinner-background {
      background-color: $spinner-background-color;
    }

    .fullpage-spinner {
      border-top-color: $spinner-color;
    }
  }
}
