@use "assets/styles/global" as *;

.order-comment-content {
  margin-top: 1rem;

  .text-area-label {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .character-count {
      margin: 0;
      font-size: 14px;
    }
  }

  .order-comment {
    min-height: 60px;
    display: block;
    width: calc(100% - 2rem);
    border: 0;
    resize: none;
    padding: 1em;
    font-weight: 500;
    font-size: 16px;

    font-family: $main-font;
    color: $input-text-color;
    background-color: $input-color;
    border-radius: 8px;

    &:focus {
      outline: none;
    }
  }
}
