@use "assets/styles/global" as *;

.checkout-content .orderlist {
  .list-item {
    padding: 0 0 1.5rem 0;

    @include md {
      width: calc(100% - 1rem);
      padding: 0.5rem;
      margin: 0;
    }
  }

  .list-item-name {
    width: 45%;

    @include md {
      margin: 0;
    }
  }

  .counter {
    min-width: 84px;
    min-width: calc(22px + 22px + 1rem + 2rem);
  }
}
