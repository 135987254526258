/********* Colors *********/
$primary-color: #ececec;
$secondary-color: #303030;

$background-color: white;
$box-shadow-color: rgba(0, 0, 0, 0.1);
$border-color: #ececec;
$error-color: #b8292f;

$input-color: #f1f1f1;
$input-text-color: #303030;

$text-color: #303030;
$page-title-color: #303030;

$grid-color: #404040;
$grid-text-color: white;

$button-color: #ececec;
$button-text-color: #303030;
$accent-button-color: #ececec;
$accent-button-text-color: #303030;
$muted-button-color: #ececec;
$muted-button-text-color: #303030;

$minicart-bubble-color: #f1f1f1;
$minicart-bubble-text-color: #303030;

$sidebar-color: white;
$sidebar-text-color: #303030;
$sidebar-border-color: #dddddd;
$sidebar-accent-color: #ececec;
$sidebar-accent-text-color: #303030;

$counter-color: #303030;
$counter-icon-color: white;
$counter-text-color: #303030;

$spinner-background: rgba(48, 60, 55, 0.15);
$spinner-color: rgba(48, 60, 55, 0.8);

/********* Fonts *********/
$main-font: Arial, sans-serif;
$accent-font: Arial, sans-serif;

/********* Misc *********/
$fasttrack-indicator-height: 26px;
