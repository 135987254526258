@use "assets/styles/global" as *;

.choice-list {
  margin-top: 1rem;

  @include md {
    max-width: 65%;
  }
}

.choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  padding: 1rem 0.5rem;
  max-height: 100%;
  font-size: 14px;

  box-shadow: $box-shadow-color 0px 0px 2px;

  @include md {
    padding: 1.25rem 1rem;
    font-size: 16px;
  }

  .choice-name,
  .choice-price {
    margin: 0;
    font-weight: bold;
  }

  .choice-name {
    flex-grow: 1;
  }

  .choice-price {
    width: 15%;
  }

  .counter {
    .counter-text-extra {
      display: none;
    }
  }
}

.choice-list-collapse {
  display: flex;
  justify-content: center;

  .collapsible-icon {
    margin-top: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}
