@use "assets/styles/global" as *;

.language-picker {
  height: 100%;
  position: relative;

  &.open .current-language span {
    opacity: 0;
  }

  .current-language img,
  .language-picker-list button {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  .current-language {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    span {
      font-size: 12px;
      display: none;

      @include md {
        display: block;
      }
    }
  }

  .language-picker-list {
    left: 50%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 30px;
    transform: translateX(-50%);
    z-index: 11;

    button {
      border: 1px solid white;
    }
  }
}
