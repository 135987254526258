@use "assets/styles/global" as *;

.orderlist-item {
  position: relative;
  padding: 0.5rem 0;
  font-size: 14px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  cursor: pointer;

  @include md {
    font-size: 16px;

    cursor: initial;
  }
}

.orderlist-item-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  width: 4rem;
  min-width: 4rem;
  height: 3rem;

  margin-right: 0.5rem;

  @include md {
    width: 5rem;
    min-width: 5rem;
    height: 4rem;

    margin-right: 1rem;
  }
}

.orderlist-item-name-container {
  flex-grow: 1;

  display: flex;
  align-items: flex-start;

  .orderlist-item-name {
    width: 20%;

    flex-grow: 1;
    text-align: left;
    margin: 0rem;
    font-weight: 900;
  }

  .name-text,
  .count-text {
    font-weight: bold;
    text-transform: none;
    font-size: 15px;

    @include md {
      font-size: 16px;
    }
  }

  .orderlist-item-modifier {
    font-weight: 100;
    text-transform: none;
    font-style: italic;
    font-size: 13px;
  }
}

.orderlist-item .counter {
  @include md {
    margin: 0 1rem 0 0;
    position: relative;
  }

  .count-btn {
    background-size: 7px;
    border-radius: 18px;
    width: 18px;
    height: 18px;

    @include md {
      background-size: 12px;
      border-radius: 22px;
      width: 22px;
      height: 22px;
    }
  }

  .counter-text {
    font-size: 12px;
  }
}

.orderlist-item-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @include md {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-width: 70px;
  }

  .orderlist-item-price {
    margin: 0 0 0 0.5rem;
    font-weight: 900;
    text-align: right;

    @include md {
      margin: 0;
    }
  }
}

// Handling counter differently on mobile and desktop
.orderlist-item {
  .counter {
    display: none;
  }

  .counter-open {
    .count-text {
      display: none;
    }

    .counter {
      display: flex;
    }
  }

  @include md {
    .count-text {
      display: none;
    }

    .counter {
      display: flex;
    }

    .counter-open {
      .count-text {
        display: none;
      }
    }
  }
}

// List is disabled after ordering, show fixed count on mobile
.orderlist.disabled .orderlist-item .orderlist-item-name .count-text {
  display: inline-block;
}

// Hiding edit options for bag fee
.orderlist-item.disabled {
  cursor: initial;

  .orderlist-item-name {
    .count-text {
      display: inline-block;
    }
  }

  .counter {
    display: none;

    @include md {
      display: none;
    }
  }
}
