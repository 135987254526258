@use "assets/styles/global" as *;

.minicart-section {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  height: 26px;

  @include md {
    align-items: center;
    height: 45px;
  }
}

.minicart {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  &.click-allowed {
    &:before {
      left: auto;
    }

    @include md {
      &:before {
        left: auto;
      }
    }
  }

  .minicart-image {
    background-image: url("https://billedarkiv.blob.core.windows.net/images/c265fb0a-f7af-41e2-9bcd-ca36cd6a1429");
    background-repeat: no-repeat;
    background-size: contain;
    height: 26px;
    position: relative;
    width: 26px;

    .ordernumber {
      position: absolute;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      text-indent: 0;

      width: 16px;
      height: 16px;
      line-height: 16px;

      -moz-border-radius: 50%;
      border-radius: 50%;

      background-color: $minicart-bubble-color;
      color: $minicart-bubble-text-color;

      bottom: 70%;
      left: 60%;

      @include md {
        width: 18px;
        height: 18px;
        line-height: 18px;
      }
    }
  }

  .ordertotal {
    font-weight: normal;
    font-size: 12px;
    display: none;

    @include md {
      display: block;
    }
  }

  .basket-total-highlight {
    font-weight: bold;
  }

  &:focus {
    outline: none;
  }
}
