@use "assets/styles/global" as *;

.sidebar-container {
  animation-duration: 500ms;
  animation-name: mobile-navigation-fadein;
}

.sidebar {
  display: none; // Hiding on mobile, unless in the right container

  background-color: $sidebar-color;

  margin: 0;
  padding: 0;

  flex-direction: column;
  justify-content: flex-start;

  height: 100%;
  overflow-y: auto;

  @include md {
    display: flex; // Always showing on desktop
    position: relative;

    margin-bottom: 1rem;
    padding: 0;
  }

  .sidebar-item {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 1rem;

    border-bottom: 1px solid $sidebar-border-color;
    color: $sidebar-text-color;

    cursor: pointer;

    &:hover {
      background-color: $sidebar-accent-color;
      color: $sidebar-accent-text-color;
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }

    @include md {
      border-bottom: none;
      border-radius: 6px;
    }
  }

  .active {
    background-color: $sidebar-accent-color;
    color: $sidebar-accent-text-color;

    @include md {
      background-color: $sidebar-accent-color;
      color: $sidebar-accent-text-color;
    }
  }
}

// Sidebar på mobil
.header-container {
  .sidebar-container {
    overflow: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    background-color: $sidebar-color;
    transform: translate3d(0, 0, 0);

    .sidebar {
      display: flex;

      .active .dot {
        height: 8px;
        width: 8px;
        margin-right: 1rem;
        background-color: $sidebar-accent-text-color;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }
}
