@use "assets/styles/global" as *;

.fasttrack-indicator {
  width: 100%;
  height: $fasttrack-indicator-height;
  background-color: #06ffac;

  display: flex;
  justify-content: center;
  align-items: center;
}

.fasttrack-indicator-text {
  font-size: 10px;
  font-weight: bold;
  margin: 0;
}
