@use "assets/styles/global" as *;

.category-grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.gridbox {
  width: calc(50% - 20px);
  margin-bottom: 1rem;
  align-self: stretch;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;

  border-radius: 8px;
  overflow: hidden;

  animation-duration: 500ms;
  animation-name: fadein;

  @include md {
    width: calc(25% - 20px);
  }

  .gridbox-image {
    height: 130px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include md {
      height: 220px;
    }
  }

  .gridbox-title {
    margin: 0;
    padding: 1rem;
    text-align: left;
    min-height: 3rem;
    font-size: 16px;
    overflow-wrap: break-word;

    background-color: $grid-color;
    color: $grid-text-color;

    @include md {
      font-size: 1.3em;
      overflow-wrap: normal;
      padding: 1.5rem;
      min-height: 4rem;
    }
  }
}
