@use "assets/styles/global" as *;

.product-modifier-container {
  padding: 0 1rem 1rem 1rem;

  animation-duration: 500ms;
  animation-name: fadein;

  @include md {
    padding: 1rem;
  }

  .modifier {
    position: relative;
    padding: 1rem 0 0.75rem 0;
  }

  .modifier-title {
    position: relative;
    font-weight: 700;
    font-size: 20px;
    padding-right: 2rem;

    cursor: pointer;

    @include md {
      font-size: 26px;
    }

    .modifier-counter {
      font-size: 24px;
      line-height: 1.05;

      @include md {
        font-size: 32px;
      }
    }

    .collapsible-icon {
      position: absolute;
      right: 0;
    }
  }

  .single-missing-choices-warning {
    display: block;

    text-align: center;
    font-size: 0.8em;
    margin: 0.5rem 0 0 0;
    font-weight: bold;

    position: relative;
    width: 100%;

    color: $error-color;

    @include md {
      margin: 0;
      position: absolute;
    }
  }

  .collapsed-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .collapsed-chosen-options,
    .collapsed-price {
      margin: 0.5rem 0;
      font-size: 14px;
      font-style: italic;

      @include md {
        font-size: 15px;
      }
    }

    .collapsed-chosen-options {
      max-width: 80%;
    }

    .collapsed-price {
      right: 0;
    }
  }
}

.product-modifier-footer {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0;

  .missing-choices-warning {
    position: relative;

    font-weight: bold;
    font-size: 14px;
    text-align: center;

    color: $error-color;

    @include md {
      font-size: 16px;
    }
  }

  .action-button.continue-button,
  .simple-total {
    font-size: 16px;

    @include md {
      font-size: 18px;
    }
  }
}

// Handling collapsed/expanded modifier

.modifier.collapsible-section {
  &.expanded {
    .single-missing-choices-warning,
    .collapsed-text {
      display: none;
    }
  }

  &.collapsed {
    .choice-list {
      margin: 0;

      .choice {
        display: none;
      }
    }
  }
}
