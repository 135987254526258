@use "assets/styles/global" as *;

.header {
  box-sizing: border-box;
  position: relative;
  height: 80px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  width: -webkit-fill-available;
}

.header-section {
  box-sizing: border-box;
  flex-grow: 1;
  width: 0;

  @include md {
    max-width: 33%;
  }
}

.mobile-sidebar-trigger {
  // The button in the mobile header to show the sidebar

  .mobile-sidebar-button {
    font-size: 28px;
    font-weight: normal;

    color: $text-color;
    background: transparent;

    height: 50px;
    width: 50px;

    padding: 0;
    margin-bottom: 7px;

    border: none;
    user-select: none;
    outline: none;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include md {
      display: none;
    }
  }
}
