@use "assets/styles/global" as *;

.smiley {
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 48px;
  width: 40px;

  img {
    height: 100%;
    width: 100%;
  }
}
