@use "assets/styles/global" as *;

.payment-section .restaurant-info {
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem 0;

  .location-info-text {
    span {
      display: inline-block;
    }

    span:not(:last-child) {
      &::after {
        content: ",";
        margin-right: 3px;
      }
    }
  }
}

.paymentcards {
  background-image: url("https://billedarkiv.blob.core.windows.net/images/83fd46c2-ec5c-4218-923a-1156b0c7c195");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  width: 100%;
  height: 2.5rem;
  margin-top: 2rem;
}
