@use "assets/styles/global" as *;

.delivery-fee {
  position: relative;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .delivery-fee-background {
    background-color: $primary-color;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  .delivery-fee-text,
  .delivery-fee-price {
    margin: 1rem 1.5rem;
    font-weight: bold;
  }
}
