html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text-color;
  background-color: $background-color;
}

// Scrollbar bug fix on mobile
@media screen and (min-width: 960px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
    margin-left: 0;
  }
}

#root {
  min-height: 100vh;
  touch-action: manipulation;

  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  font-weight: bold;
  color: $text-color;
}

button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $main-font;

  &:focus {
    outline: none;
  }
}

.button {
  border-radius: 6px;
  border: none;
  text-align: center;
  padding: 16px 20px;
  font-size: 16px;

  background: $button-color;
  color: $button-text-color;

  &:hover {
    cursor: pointer;
  }

  &.accent-button {
    background-color: $accent-button-color;
    color: $accent-button-text-color;
  }

  &.muted-button {
    background-color: $muted-button-color;
    color: $muted-button-text-color;
  }
}

.button:disabled,
.button[disabled] {
  cursor: auto;
  opacity: 0.7;

  background-color: $muted-button-color;
  border-color: $muted-button-color;
  color: $muted-button-text-color;
}
