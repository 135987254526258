.oasia,
.bistecca,
.jacobsen {
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  .header-container .header {
    .language-picker span {
      color: #fff;
    }

    .minicart .minicart-image {
      background-image: url("https://billedarkiv.blob.core.windows.net/images/376dc013-3973-4581-816a-c4b1be1fce29");
    }
  }

  .page-view-content.inner {
    background: none;
  }

  .precheckout-content {
    .top-div,
    .bottom-div .precheckout-subtitle,
    .total.simple-total {
      display: none;
    }

    .product-price,
    .product-price-text span {
      font-size: 13px;
    }
  }

  .checkout-content {
    .discount-container,
    .giftcard-container {
      @include md {
        max-width: 400px;
      }
    }

    .terms-checkbox {
      text-align: center;

      @include md {
        text-align: right;
      }
    }
  }

  .error-message-content,
  .confirmation-content,
  .maintenance-page .content-body {
    box-sizing: border-box;
    padding: 1rem;
  }
}
