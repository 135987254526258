.jacobsen {
  $black: #000;
  $white: #fff;
  $transparent-white: rgba(255, 255, 255, 0.8);
  $light-gray: #ececec;
  $gray: #aaa;
  $dark-gray: #303030;
  $gold: #c0a737;
  $dark-green: #163a20;
  $green: #64b544;

  $border-color: $white;
  $link-color: $black;

  $muted-text-color: $gray;
  $header-text-color: $white;

  $button-background-color: $dark-green;
  $button-text-color: $white;
  $button-accent-background-color: $green;
  $button-accent-text-color: $white;
  $button-muted-background-color: $transparent-white;
  $button-muted-text-color: $black;
  $button-gray-background-color: $light-gray;
  $button-gray-text-color: $black;

  $header-background: none;

  $page-title-color: $gold;

  $minicart-text-color: $white;
  $minicart-bubble-background-color: $gold;
  $minicart-bubble-text-color: $white;

  $sidebar-background-color: $transparent-white;
  $sidebar-text-color: $black;
  $sidebar-accent-background-color: $white;
  $sidebar-hover-background-color: $white;

  $cart-background-color: $dark-green;
  $cart-text-color: $white;
  $cart-counter-text-color: $white;

  $grid-background-color: $transparent-white;
  $grid-text-color: $black;

  $product-background-color: $white;
  $precheckout-background-color: $dark-green;
  $precheckout-text-color: $white;
  $checkout-background-color: $white;
  $content-background: none;
  $content-text-color: $white;

  $counter-background-color: $black;
  $counter-text-color: $dark-gray;
  $counter-accent-text-color: $dark-gray;

  $cart-color: #163a20;
  $cart-text-color: #fff;
  $cart-border-color: #ececec;

  $footer-color: #fff;
  $footer-text-color: #000;

  $precheckout-text-color: #fff;

  $counter-color: #000;
  $counter-icon-color: #fff;
  $counter-text-color: #303030;
  $counter-accent-text-color: #303030;

  $spinner-background: rgba(255, 255, 255, 0.15);
  $spinner-color: rgba(255, 255, 255, 0.8);

  $main-font: "Lato", sans-serif;

  font-family: $main-font;

  a {
    color: $link-color;
  }

  .button {
    background-color: $button-background-color;
    color: $button-text-color;
    font-family: $main-font;
  }

  .header-container {
    background: $header-background;

    @include md {
      background: $header-background;
    }

    .header {
      background: $header-background;

      .mobile-sidebar-button {
        color: $header-text-color;
      }

      .minicart {
        .ordertotal {
          color: $minicart-text-color;
        }

        .ordernumber {
          background-color: $minicart-bubble-background-color;
          color: $minicart-bubble-text-color;
        }
      }
    }

    .sidebar-container .sidebar {
      background-color: $sidebar-background-color;

      .sidebar-item {
        color: $sidebar-text-color;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 600;

        &.active {
          background-color: $sidebar-accent-background-color;
        }

        &:hover {
          background-color: $sidebar-hover-background-color;
        }

        .dot {
          background-color: $sidebar-text-color;
        }
      }
    }
  }

  .expandedcart .expandedcart-inner {
    background-color: $cart-background-color;
    color: $cart-text-color;
    padding: 1rem;

    .clear-cart {
      color: $text-color;
      opacity: 0.7;
    }

    .counter-text-number-container {
      color: $cart-counter-text-color;
    }

    .cart-summary {
      background-color: inherit;
    }

    .cart-cta-button .button {
      background-color: $button-accent-background-color;
      color: $button-accent-text-color;
    }
  }

  .content-title-container.page-title-container {
    box-sizing: border-box;

    .page-title {
      border-bottom: 1px solid $border-color;
      box-sizing: border-box;
      color: $page-title-color;
      font-weight: 300;
      font-size: 24px;
      padding: 1rem 0;
      width: auto;

      @include md {
        font-size: 45px;
        margin-left: -1rem;
        width: calc(100% + (2 * 1rem));
      }
    }

    .page-subtitle {
      color: $page-title-color;
    }
  }

  .category-grid {
    .gridbox-image {
      height: auto;
      padding: 45%;
      width: auto;
    }

    .gridbox-title {
      background-color: $grid-background-color;
      color: $grid-text-color;
    }
  }

  .sidebar {
    @include md {
      background-color: $sidebar-background-color;
    }

    .sidebar-item {
      border-radius: 0;
      border: none;
      color: $sidebar-text-color;
      font-weight: 600;
      font-size: 14px;

      &.active,
      &:hover {
        @include md {
          background-color: $sidebar-hover-background-color;
          color: $sidebar-text-color;
        }
      }
    }
  }

  .content-body .product-list .product {
    background-color: $product-background-color;
    border-radius: 8px;

    .product-image {
      height: auto;
      padding: 30%;

      @include md {
        padding: 0;
        width: 20%;
      }
    }

    .product-action-container {
      .product-price {
        font-size: 24px;

        span {
          font-size: 19px;
        }

        @include md {
          font-size: 40px;

          span {
            font-size: 32px;
          }
        }
      }

      .modifier-button {
        background-color: $button-muted-background-color;
        border: none;
        color: $button-muted-text-color;
        font-family: $main-font;

        .collapsible-icon {
          background-image: url("https://billedarkiv.blob.core.windows.net/images/17bb23e7-36f9-4d82-ad8b-660731c704ec");
        }
      }
    }

    .product-modifier-container {
      .modifier-title {
        font-family: $main-font;
        font-size: 1rem;
      }
    }
  }

  .counter {
    .counter-text {
      color: $counter-text-color;
    }

    .count-btn {
      background-color: $counter-background-color;
    }
  }

  .product-modifier-footer {
    .simple-total {
      border: none;
      font-family: $main-font;
      font-size: 8px;

      @include md {
        font-size: 20px;
      }
    }
  }

  .precheckout-content {
    background-color: $precheckout-background-color;
    color: $precheckout-text-color;

    .precheckout-products .product {
      background: none;

      .product-name,
      .product-price {
        color: $precheckout-text-color;
        font-family: $main-font;
      }

      .counter-text-number {
        color: $precheckout-text-color;
      }
    }

    .continue-button {
      background-color: $button-muted-background-color;
      color: $button-muted-text-color;
    }
  }

  .checkout-content .content-sections {
    background-color: $checkout-background-color;
    padding: 0.5rem;

    .room-service-message.jacobsen {
      display: block;
    }

    .total-title,
    .total-amount {
      font-size: 22px;
    }

    .content-title-container .content-title {
      font-size: 26px;
    }

    .delivery-picker-option {
      color: $muted-text-color;

      &.active {
        background-color: $button-background-color;
        color: $button-text-color;
      }
    }

    .react-datepicker__input-container input {
      font-family: $main-font;
    }

    .finalize-buttons .button {
      &.pay-online-button {
        background-color: $button-accent-background-color;
        color: $button-accent-text-color;
      }

      &.pay-at-pickup-button {
        background-color: $button-gray-background-color;
        color: $button-gray-text-color;
      }
    }

    .pay-online-button {
      background-color: $accent-button-color;
    }
  }

  .error-message-content,
  .confirmation-content,
  .maintenance-page .content-body {
    background-color: $content-background;
    color: $content-text-color;

    .button {
      background-color: $button-muted-background-color;
      color: $button-muted-text-color;
    }
  }
}
