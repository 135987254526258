@use "assets/styles/global" as *;

.confirmation-content {
  .restaurant-info .location-info-text {
    .location-name {
      display: block;
      font-weight: bold;
    }

    .location-email,
    .location-cvr {
      display: none;
    }
  }

  .order-section {
    @include md {
      padding: 0 2rem;
    }
  }

  .order-title {
    margin: 1.5rem 0;
  }

  .orderlist .list-item {
    padding-bottom: 1rem;
  }

  .total-title,
  .total-amount {
    font-weight: normal;
    font-size: 20px;

    @include md {
      font-size: 24px;
    }
  }
}

.confirmation-text-title {
  font-weight: bold;
}
