@use "assets/styles/global" as *;

.precheckout-content {
  border-radius: 8px;
  box-shadow: $box-shadow-color 0px 0px 15px;

  .precheckout-section {
    padding: 1rem;

    @include md {
      margin: 0 1rem;
    }
  }

  .top-div {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $border-color;
    text-align: center;

    @include md {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .precheckout-subtitle {
      margin-bottom: 1rem;

      @include md {
        margin: 0;
      }
    }
  }

  .bottom-div {
    .precheckout-subtitle {
      margin-top: 1rem;
    }

    .simple-total {
      border-top: none;
      padding-bottom: 0;
    }
  }

  .precheckout-footer {
    border-top: 1px solid $border-color;
    display: flex;

    .continue-button {
      flex-grow: 1;
    }
  }
}

.precheckout-products {
  margin: 2rem 0;

  .product {
    box-shadow: none;
    margin-bottom: 2rem;
  }

  .product-content {
    .product-image {
      display: none;
    }

    .product-details {
      flex-direction: row;
      justify-content: space-between;
      padding: 0;

      @include md {
        align-items: center;
      }
    }

    .product-name-container {
      .product-name {
        font-size: 16px;
      }

      .product-description {
        display: none;
      }
    }

    .product-action-container {
      border: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .product-price,
    .product-price .product-price-text {
      font-size: 16px;
    }

    .product-price {
      margin: 0 1rem 0 0;
    }

    .counter {
      .counter-text-extra {
        display: none;
      }
    }
  }
}
