@use "assets/styles/global" as *;

.date-and-time-pickers {
  display: flex;
  flex-wrap: wrap;

  .datepicker-section {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2px;

    @include md {
      width: 49%;
      margin-right: 2%;
    }
  }

  .timepicker-section {
    width: 100%;

    @include md {
      width: 49%;
    }
  }
}

/* Placeholder styling inputs */
.datepicker-section .react-datepicker-wrapper input,
.timepicker-section .timepicker .timepicker-trigger {
  border-radius: 6px;

  ::placeholder {
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $main-font;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $main-font;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $main-font;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $main-font;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-weight: 900;
    text-transform: none;
    font-size: 16px;
    color: $text-color;
    font-family: $main-font;

    opacity: 1;
  }
}
