.bistecca {
  $red: #800020;
  $white: #fff;
  $gray: #423e3e;

  $link-color: $red;

  $button-background-color: $red;
  $button-text-color: $white;

  $header-background-color: $red;
  $header-text-color: $white;

  $border-color: $red;
  $page-title-color: $white;

  $minicart-text-color: $white;
  $minicart-bubble-background-color: $gray;
  $minicart-bubble-text-color: $white;

  $sidebar-background-color: $red;
  $sidebar-text-color: $white;
  $sidebar-border-color: $white;
  $sidebar-hover-background-color: $gray;
  $sidebar-accent-background-color: $gray;
  $sidebar-accent-text-color: $white;

  $counter-background-color: $red;
  $counter-text-color: $gray;

  $grid-background-color: $red;
  $grid-text-color: $white;

  $precheckout-background-color: $white;
  $checkout-background-color: $white;
  $content-background-color: $white;

  $spinner-background-color: rgba(48, 60, 55, 0.15);
  $spinner-color: rgba(48, 60, 55, 0.8);

  $main-font: "Raleway", sans-serif;
  $accent-font: "Merriweather", sans-serif;

  $header-height-md: 190px;

  font-family: $main-font;

  a {
    color: $link-color;
  }

  .button {
    background-color: $button-background-color;
    color: $button-text-color;
    font-family: $main-font;
  }

  .header-container {
    background-color: $header-background-color;

    @include md {
      background: none;
    }

    .header {
      background-color: $header-background-color;

      @include md {
        background: none;
        height: $header-height-md;
      }

      .logo {
        @include md {
          height: 176px;
          max-height: none;
          max-width: none;
          width: 176px;
        }
      }

      .mobile-sidebar-button {
        color: $header-text-color;
      }

      .language-picker span {
        font-family: $accent-font;
      }

      .minicart {
        .ordernumber {
          background-color: $minicart-bubble-background-color;
          color: $minicart-bubble-text-color;
          font-family: $main-font;
        }

        .ordertotal {
          font-family: $accent-font;
          color: $minicart-text-color;
        }
      }
    }

    .sidebar-container .sidebar {
      background-color: $sidebar-background-color;

      .sidebar-item {
        color: $sidebar-text-color;
        font-family: $main-font;

        &.active {
          background-color: $sidebar-accent-background-color;
        }

        &:hover {
          background-color: $sidebar-hover-background-color;
          color: $sidebar-text-color;
        }

        .dot {
          background-color: $sidebar-text-color;
        }
      }
    }
  }

  .expandedcart .expandedcart-inner {
    .cart-title {
      font-family: $accent-font;
    }

    .cart-cta-button .button {
      font-family: $main-font;
    }
  }

  .page-view {
    @include md {
      padding-top: $header-height-md;
    }
  }

  .content-title-container.page-title-container {
    box-sizing: border-box;

    .page-title {
      border-bottom: 1px solid $border-color;
      box-sizing: border-box;
      color: $page-title-color;
      font-family: $accent-font;
      font-size: 24px;
      margin: 1rem;
      margin-top: 0;
      padding: 1rem 0;
      width: auto;

      @include md {
        font-size: 40px;
      }
    }

    .page-subtitle {
      color: $page-title-color;
    }
  }

  .category-grid {
    .gridbox-image {
      height: auto;
      padding: 45%;
      width: auto;
    }

    .gridbox-title {
      background-color: $grid-background-color;
      color: $grid-text-color;
      font-family: $accent-font;
    }
  }

  .sidebar {
    @include md {
      background-color: $sidebar-background-color;
    }

    .sidebar-item {
      border-radius: 0;
      color: $sidebar-text-color;

      &.active,
      &:hover {
        @include md {
          background-color: $sidebar-hover-background-color;
          color: $sidebar-text-color;
        }
      }
    }
  }

  .content-body .product {
    background-color: $content-background-color;
    border-radius: 8px;

    .product-content {
      @include md {
        min-height: 17rem;
      }
    }

    .product-image {
      height: auto;
      padding: 30%;

      @include md {
        padding: 0;
        width: 20%;
      }
    }

    .product-name {
      font-family: $accent-font;
    }

    .product-action-container {
      .product-price {
        font-family: $accent-font;
        font-size: 24px;

        span {
          font-size: 19px;
        }

        @include md {
          font-size: 40px;

          span {
            font-size: 32px;
          }
        }
      }

      .modifier-button {
        background-color: $button-background-color;
        border: none;
        color: $button-text-color;
        font-family: $main-font;

        .collapsible-icon {
          background-image: url("https://billedarkiv.blob.core.windows.net/images/17bb23e7-36f9-4d82-ad8b-660731c704ec");
        }
      }
    }

    .product-modifier-container {
      .modifier-title {
        font-family: $accent-font;
        font-size: 1rem;

        @include md {
          font-size: 26px;
        }
      }
    }
  }

  .counter {
    .counter-text {
      color: $counter-text-color;
    }

    .count-btn {
      background-color: $counter-background-color;
    }
  }

  .product-modifier-footer {
    .simple-total {
      border: none;
      font-family: $accent-font;
      font-size: 8px;

      @include md {
        font-size: 20px;
      }
    }
  }

  .precheckout-content {
    background-color: $precheckout-background-color;

    .precheckout-products {
      .product-name,
      .product-price {
        font-family: $main-font;
      }
    }
  }

  .checkout-content .content-sections {
    background-color: $checkout-background-color;
    padding: 0.5rem;

    .room-service-message.bistecca {
      display: block;
    }

    .total-title,
    .total-amount {
      font-family: $accent-font;
      font-size: 22px;
    }

    .content-title-container .content-title {
      font-family: $accent-font;
      font-size: 26px;
    }

    .delivery-picker-option.active {
      background-color: $button-background-color;
      color: $button-text-color;
    }

    .react-datepicker__input-container input {
      font-family: $main-font;
    }

    .finalize-buttons .button {
      font-family: $main-font;
    }
  }

  .error-message-content,
  .confirmation-content,
  .maintenance-page .content-body {
    background-color: $content-background-color;
  }

  .spinner-section {
    .spinner-background {
      background-color: $spinner-background-color;
    }

    .fullpage-spinner {
      border-top-color: $spinner-color;
    }
  }
}
