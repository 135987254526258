@use "assets/styles/global" as *;

.checkbox-container {
  margin: 1rem 0;

  .checkbox-label {
    display: flex;
    white-space: pre-wrap;
    cursor: pointer;
  }

  .checkbox {
    margin: 0.5rem 1rem 0.5rem 0;
    cursor: pointer;

    @include md {
      margin: 0.5rem 1rem;
    }
  }
}
