@use "assets/styles/global" as *;

.expandedcart {
  width: 100%;
  background-color: $background-color;

  margin: 0;
  padding: 0;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: auto;

  flex-grow: 1;

  transform: translate3d(0, 0, 0); // iOS Safari hack

  animation-duration: 500ms;
  animation-name: mobile-navigation-fadein;

  display: flex;
  flex-direction: column;

  @include md {
    position: relative;
    flex-direction: row;

    width: 100%;
    height: auto;
    max-height: auto;
    overflow: visible;

    padding: 0;
    bottom: 0;

    margin: 0;

    animation-name: none;
  }

  .expandedcart-inner {
    position: relative;
    margin: 0;
    text-align: center;
    padding: 0 0.75rem;
    overflow: hidden;

    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: stretch;

    padding: 1rem;

    @include md {
      margin: 0 auto;
      padding: 0;
      overflow: auto;
    }

    .empty-orderlist {
      text-align: left;
    }

    .simple-total {
      text-align: left;
    }

    .total-amount {
      text-align: left;
    }
  }

  .expanded-cart-section {
    @include md {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .orderlist {
    overflow: auto;
    padding-bottom: 80px; // 80px makes room for the fixed cart total

    @include md {
      padding-bottom: 0;
    }
  }

  .empty-cart-container {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .cart-title-container {
    display: flex;
    align-items: center;

    margin: 1.5rem 0;

    .cart-title {
      font-weight: bold;
      font-size: 20px;

      @include md {
        font-size: 28px;
      }
    }

    .clear-cart {
      margin: 0 0 0 1.5rem;
      font-size: 14px;

      background-color: #cecece;
      padding: 4px 11px;
      border-radius: 6px;

      cursor: pointer;
    }
  }

  .cart-summary {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 80px;
    width: 80%;
    width: calc(100% - 4rem);
    padding: 0 2rem;

    background-color: $background-color;
    border-top: 1px solid $border-color;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include md {
      position: relative;
      justify-content: flex-end;
      margin-top: 1rem;
    }
  }

  .simple-total {
    text-align: right;
    padding: 0;
    border: none;
    display: block;

    .total-title {
      font-weight: bold;
      margin: 0;
      opacity: 0.5;
      font-size: 11px;

      @include md {
        font-size: 12px;
      }
    }

    .total-amount {
      margin: 0;
      font-size: 16px;
      font-weight: bold;

      @include md {
        font-size: 20px;
      }
    }
  }

  .cart-cta-button {
    margin-left: 2rem;

    .button {
      font-size: 14px;
      padding: 0.7rem 1rem;
      min-width: 8rem;
      border-radius: 10px;

      @include md {
        padding: 0.8rem 2rem;
      }
    }
  }
}
