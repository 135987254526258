@use "assets/styles/global" as *;

.bagfee-checkbox {
  margin: 0 0 2rem 0;
  font-size: 0.9em;

  @include md {
    margin: 1.5rem 0;
  }

  .checkbox-container {
    .checkbox {
      margin: 0;
      margin-left: 0.5rem;
    }

    .checkbox-label {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
    }
  }

  input {
    margin-left: 1rem;
  }
}
