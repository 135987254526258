@use "assets/styles/global" as *;

.counter {
  display: flex;
  align-items: center;

  text-align: center;
  justify-content: space-between;
  font-size: 16px;

  .counter-text {
    margin: 0;
    min-width: 4px;
    font-weight: bold;
    text-align: center;
    color: $counter-text-color;
  }

  .count-btn {
    border: 0;
    padding: 0;

    border-radius: 24px;
    width: 24px;
    height: 24px;

    text-indent: -999em;
    color: $counter-icon-color;

    background-color: $counter-color;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 14px;

    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  .minus {
    margin-right: 0.5rem;
  }

  .plus {
    margin-left: 0.5rem;
  }
}
