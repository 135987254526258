@use "assets/styles/global" as *;

.checkout-error {
  width: 100%;
  text-align: right;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  color: $error-color;
}
