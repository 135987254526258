@use "assets/styles/global" as *;

.more-button {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  justify-content: space-between;

  @include md {
    display: flex;
  }

  .icon {
    font-size: 22px;
  }

  .text {
    font-size: 12px;
  }
}
