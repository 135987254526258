@use "assets/styles/global" as *;

.finalize-buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 2rem;

  .button {
    margin: 0.5rem 0;
    width: 100%;

    @include md {
      margin: 0 0 0 1rem;
      width: auto;
    }
  }

  .accent-button {
    padding: 1rem 4rem;
    margin: 0 0 0.5rem 0;

    @include md {
      margin: 0;
    }
  }
}

.button.mobilepay-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 3rem;
  background-color: #5a78ff;
  border-color: #5a78ff;
  color: white;

  .mobilepay-logo {
    background-image: url("https://billedarkiv.blob.core.windows.net/images/869702b5-8fed-4fd8-8070-07f3fc514d92");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    height: 1.5rem;
    width: 1.5rem;
    margin-right: 8px;
  }
}
