@use "assets/styles/global" as *;

.page-title-container {
  padding: 1.5rem 0;

  @include md {
    padding: 2rem 0;
  }
}

.page-title {
  width: 100%;
  font-size: 1.8rem;
  text-align: center;
  white-space: pre-wrap;

  color: $page-title-color;

  @include md {
    font-size: 2.8rem;
  }
}

.page-subtitle {
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin: 1rem 0 0 0;
  min-height: 1rem;

  color: $page-title-color;
}
