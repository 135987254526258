@use "assets/styles/global" as *;

.restaurant-info {
  text-align: center;
  margin: 1rem 0;

  .location-info-text {
    margin: 0;

    span {
      display: block;
    }
  }
}
