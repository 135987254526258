.inner {
  box-sizing: border-box;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 1rem;

  color: $text-color;
  background-color: $background-color;
}

.noscroll {
  // Preventing scroll on main content when overlay on mobile
  overflow: hidden;

  @include md {
    overflow: auto;
  }
}

.page-view {
  padding-top: 80px; // The height of the fixed header
  padding-bottom: 2rem;

  @include md {
    padding-top: 100px; // The height of the fixed header
  }
}

// Layout for any pages with no other content than a status - e.g. error, maintenance, order, confirmation

.status-page {
  .status-page-text {
    width: 100%;
    text-align: center;
  }

  .button {
    display: block;
    margin: 1rem auto;
  }
}

.fasttrack .page-view-content.inner {
  padding-top: $fasttrack-indicator-height;
}

.content-body {
  width: 100%;

  &.sidebar-body {
    // Content with the sidebar on the left
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    @include md {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & > div:nth-child(1) {
      width: 0;

      @include md {
        width: 22%;
        margin-right: 3%;
      }
    }

    & > div:nth-child(2) {
      width: 100%;
      flex-grow: 1;

      @include md {
        width: 75%;
      }
    }
  }
}

// Accordion style with rotating icon
.collapsible-section {
  &.expanded {
    .collapsible-icon {
      animation-duration: 500ms;
      animation-name: rotate;
      transform: rotate(180deg);
    }

    .collapsible-content {
      opacity: 1;
      transition: opacity 500ms ease;
    }
  }

  &.collapsed {
    .collapsible-content {
      opacity: 0;
      visibility: hidden;
      height: 0;
      margin: 0;
    }
  }
}
