@use "assets/styles/global" as *;

.orderlist {
  overflow-y: auto;
  overflow-x: hidden;
}

.empty-orderlist {
  text-align: center;
}
